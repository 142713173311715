<template>
        <div class="inner-container">
            <div class="section-title program"><i class="icon icon-binocular-graphic"></i><span>Стратегическое планирование</span></div>
            <div class="section-subtitle"><i class="icon icon-grid"></i><span>Целевые индикаторы</span></div>
            <div v-show="!edit">
                <!----------------Место для шапки с фильтром--------------->
                <div class="filter-container">
                    <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                        <template #button-content>
                            <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i class="icon icon-keyboard"></i></span>
                        </template>
                    <div>
                        <div class="top-content">
                            <span>Параметры фильтра</span>
                            <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                        </div>
                        <div class="filter-block">
                            <b-form-group :label="yearItem">
                                <multiselect
                                    v-model="currentYear"
                                    :options="[2020,2021,2022]"
                                    placeholder="Год"
                                    @input="chgParams"
                                    :allow-empty="false"
                                    :show-labels="false"
                                />
                            </b-form-group>
                        </div>
                        <!---------------Документы СГП--------------------->
                        <div class="filter-block">
                            <b-form-group :label="sgpDoc[0].name_ru">
                                <multiselect
                                    v-model="dictProgramVal"
                                    track-by="name"
                                    label="name"
                                    placeholder="Документы СГП"
                                    :options="programArr"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    @input="loadGoals"
                                >
                                    <template
                                        slot="singleLabel"
                                        slot-scope="{ option }"
                                    >
                                        <template v-if="option.name!==undefined">
                                            <span v-if="option.name.length<16">{{ option.name }}</span>
                                            <span v-else :title="option.name">{{option.name.slice(0, 16) + '...'}}</span>
                                        </template>
                                    </template>
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!---------------Цели--------------------------->
                        <div class="filter-block">
                            <b-form-group :label="sgpDoc[1].name_ru">
                                <multiselect
                                    v-model="dictProgramGoalVal"
                                    track-by="name"
                                    label="name"
                                    placeholder="Цели"
                                    :options="programGoalArr"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    @input="chgParams"
                                >
                                    <template
                                        slot="singleLabel"
                                        slot-scope="{ option }"
                                    >
                                        <template v-if="option.name!==undefined">
                                            <span v-if="option.name.length<16">{{ option.name }}</span>
                                            <span v-else :title="option.name">{{option.name.slice(0, 16) + '...'}}</span>
                                        </template>
                                    </template>
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!---------------АБП--------------------------->
                        <div class="filter-block">
                            <b-form-group :label="sgpDoc[2].name_ru">
                                <multiselect
                                    v-model="dictAbpVal"
                                    track-by="name"
                                    label="name"
                                    placeholder="АБП"
                                    :options="abpArr"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    @input="chgParams"
                                >
                                    <template
                                        slot="singleLabel"
                                        slot-scope="{ option }"
                                    >
                                        <template v-if="option.name!==undefined">
                                            <span v-if="option.name.length<16">{{ option.name }}</span>
                                            <span v-else :title="option.name">{{option.name.slice(0, 16) + '...'}}</span>
                                        </template>
                                    </template>
                                </multiselect>
                            </b-form-group>
                        </div>
                    </div>
                    </b-dropdown>
                    <div class="filter-actions">
                        <b-button v-show="!edit" variant="primary" @click="addClk">
                            <i class="icon icon-plus-circle"></i>{{ addRecords.name_ru }}
                        </b-button>
                    </div>
                </div>
                <!---------Начало грид таблицы-------------->
                <b-progress variant="primary" v-show="bar<100" height="3px" :value="bar" striped animated></b-progress>
                <div class="table-container">
                    <b-table
                        :fields="tableFields"
                        :items="globalArr"
                        :tbody-tr-class="rowClass"
                        responsive="true"
                        bordered
                        head-variant="light"
                        sticky-header
                        no-border-collapse
                    >
                        <template #thead-top="data">
                            <b-tr class="text-center">
                                <b-th colspan="7"></b-th>
                                <b-th variant="primary" colspan="2">Факт</b-th>
                                <b-th variant="danger" colspan="5">План</b-th>
                                <b-th></b-th>
                            </b-tr>
                        </template>
                        <template #head(action)="scope">
                            <b-button @click="openAll()">
                                <i class="icon icon-chevron-circle icon-rotate-180" v-if="open"></i>
                                <i class="icon icon-chevron-circle" v-if="!open"></i>
                            </b-button>
                        </template>
                        <template #cell(action)="data">
                            <b-button v-if="data.item.levels == 1" @click="openChilds(data.item)">
                                <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                                <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                            </b-button>
                        </template>
                        <template #cell(sgp)="data">
                            <div v-if="data.item.levels == 1">{{ data.value }}</div>
                            <b-button v-if="data.item.levels == 2" @click="openChilds(data.item)">
                                <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                                <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                            </b-button>
                        </template>
                        <template #cell(goals)="data">
                            <div v-if="data.item.levels == 2">{{ data.value }}</div>
                        </template>
                        <template #cell(targetIndicators)="data">
                            <div v-if="data.item.levels == 3">{{ data.value }}</div>
                        </template>
                        <template #cell(abp)="data">
                            <div v-if="(data.item.levels == 3)"><div v-html="data.value"></div></div>
                        </template>
                        <template #cell(bp)="data">
                            <div v-if="(data.item.levels == 3)"><div v-html="data.value"></div></div>
                        </template>
                        <template #cell(unitFromStat)="data">
                            <div v-if="(data.item.levels == 3)">{{ data.value }}</div>
                        </template>
                        <template #cell(yearOne)="data">
                            <div v-if="(data.item.levels == 3)">{{ data.value }}</div>
                        </template>
                        <template #cell(yearTwo)="data">
                            <div v-if="(data.item.levels == 3)">{{ data.value }}</div>
                        </template>
                        <template #cell(yearThree)="data">
                            <div v-if="(data.item.levels == 3)">{{ data.value }}</div>
                        </template>
                        <template #cell(yearFour)="data">
                            <div v-if="(data.item.levels == 3)">{{ data.value }}</div>
                        </template>
                        <template #cell(yearFive)="data">
                            <div v-if="(data.item.levels == 3)">{{ data.value }}</div>
                        </template>
                        <template #cell(yearSix)="data">
                            <div v-if="(data.item.levels == 3)">{{ data.value }}</div>
                        </template>
                        <template #cell(yearSeven)="data">
                            <div v-if="(data.item.levels == 3)">{{ data.value }}</div>
                        </template>
                        <template #cell(more)="data">
                            <b-button class="more" v-if="(data.item.levels == 3)" @click="data.item.more = !data.item.more">
                                <i class="icon icon-more" v-if="!data.item.more"></i>
                                <i class="icon icon-close" v-else></i>
                                <div class="more-block" v-if="data.item.more">
                                    <div v-show="!edit">
                                        <ul>
                                            <li><a href="#/forecast-form" @click="editClk(data.item)">Редактировать</a></li>
                                            <li><a href="#/forecast-form" @click="addClk">Добавить</a></li>
                                            <li><a href="#/forecast-form" @click="deleteClk(data.item)">Удалить</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </b-button>
                        </template>
                    </b-table>
                </div>
            </div>
            <!--------------------------Компонент Тимура - для добавления/редактирования------------------->
            <div v-show="edit">
                <c-forecast-edit :currentForecast="currentForecast" @closeEdit="edit=false" @saved="saved"/>
            </div>
            <!-------------------------------------------------------------->
        </div>
</template>

<script>
import CForecastEdit from '@/modules/forecast/ForecastEdit.vue';
import Multiselect from 'vue-multiselect'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

export default {
    name: "ForecastForm",
    components: {
        'multiselect': Multiselect,
        'c-forecast-edit': CForecastEdit
    },
    created() {
        this.loadProgram();
        this.loadProgramGoals();
        this.loadAbp();
        this.currentYear = new Date().getFullYear();
    },
    computed: {
        // i18nString: function() {
        //     let item
        //     if (this.$i18n.locale === 'kk') {
        //         item = this.programArr.name_kk
        //     } else if (this.$i18n.locale === 'ru'){
        //         return 'ru';
        //     } else  {
        //         return 'en'
        //     }
        // }
    },
    mounted() {
        const currentYear = this.sendCurrentYear();
        // console.log('curYear: ' + currentYear);
        this.headParams = {year: currentYear, program: null, goal: null, abp: null};
        const data = this.headParams;
        this.bar = 5;
        this.getFieldsOfTable(data); // При загрузке страницы передается год из поля фильтра "Год"
    },
    data() {
        return {
            bar: 0,
            proba: [],
            all: {
                name_ru: 'Все',
                name_en: 'All',
                name_kk: 'Барлық',
                id: ''
            },
            dictProgramVal: null,
            dictProgramGoalVal: null,
            dictAbpVal: null,
            currentYear: null,
            yearItem: 'Год',
            langObj: {
                name_ru: 'ru',
                name_en: 'en',
                name_kk: 'kk'
            },
            addRecords: {
                name_ru: 'Добавить',
                name_en: 'Add',
                name_kk: 'Добавить'
            },
            sgpDoc: [
                {
                    name_ru: 'Документы СГП',
                    name_kk: 'Документы СГП',
                    name_en: 'SGP Documents'
                },
                {
                    name_ru: 'Цели',
                    name_kk: 'Цели',
                    name_en: 'Goals'
                },
                {
                    name_ru: 'АБП',
                    name_kk: 'АБП',
                    name_en: 'ABP'
                },
            ],
            programArr: [],
            programGoalArr: [],
            abpArr: [],
            edit: false,
            open: true,
            selected: '',
            openMore: false,
            paramHeader: null,
            header: null,
            extractedYear: '',
            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'sgp',
                    label: 'СГП'
                },
                {
                    key: 'goals',
                    label: 'Цели'
                },
                {
                    key: 'targetIndicators',
                    label: 'Целевые индикаторы'
                },
                {
                    key: 'abp',
                    label: 'АБП'
                },
                {
                    key: 'bp',
                    label: 'БП'
                },
                {
                    key: 'unitFromStat',
                    label: 'Ед. изм.'
                },
                {
                    key: 'yearOne',
                    label: ''
                },
                {
                    key: 'yearTwo',
                    label: ''
                },
                {
                    key: 'yearThree',
                    label: ''
                },
                {
                    key: 'yearFour',
                    label: ''
                },
                {
                    key: 'yearFive',
                    label: ''
                },
                {
                    key: 'yearSix',
                    label: ''
                },
                {
                    key: 'yearSeven',
                    label: ''
                },
                {
                    key: 'more',
                    label: ' '
                }
            ],
            forecastFormArr: [],
            mainGridObjectArr: [],
            programObjArr: [],
            goalObjArr: [],
            mainArr: [],
            globalArr: [],
            storeForEditAndDelete: [],
            storeAbpArr: [],
            forecastObjForDelete: {},
            headParams: null,
            currentForecast: null
        }
    },    //------Изменение наименования столбцов годов в зависимости от выбора года
    beforeUpdate() {    //---Изменить названия столбцов согласно выбранного года
        const currentYear = this.currentYear;
        // console.log('currentYear: ' + currentYear)
        const factYearOne = parseInt(currentYear) - 2;
        const factYearTwo = parseInt(currentYear) - 1;
        const planYearOne = parseInt(currentYear);
        const planYearTwo = parseInt(currentYear) + 1;
        const planYearThree = parseInt(currentYear) + 2;
        const planYearFour = parseInt(currentYear) + 3;
        const planYearFive = parseInt(currentYear) + 4;

        this.tableFields[7].label = factYearOne.toString();
        this.tableFields[8].label = factYearTwo.toString();
        this.tableFields[9].label = planYearOne.toString();
        this.tableFields[10].label = planYearTwo.toString();
        this.tableFields[11].label = planYearThree.toString();
        this.tableFields[12].label = planYearFour.toString();
        this.tableFields[13].label = planYearFive.toString();

        this.globalArr.forEach(row => {
            if ([1, 2].includes(row.levels)) {
                row._rowVariant = 'info';
            }
        })
    },
    methods: {
        editClk(param) {
            if (param.id) {
                const obj = this.extractObjectById(param.id, 'edit');
                console.log('EditObject: ' + JSON.stringify(obj));
                this.currentForecast = obj;
                this.edit = true;
            }
        },
        addClk() {
            this.edit = true;
            this.currentForecast = null;
        },
        deleteClk(param) {
            if (param.id) {
                // console.log(param.id)
                const obj = this.extractObjectById(param.id, 'delete');
                if (obj) {
                    this.$bvModal.msgBoxConfirm(
                        'Удалить запись?',
                        {
                            title: 'Подтверждение',
                            size: 'lg',
                            buttonSize: 'sm',
                            okVariant: 'success',
                            okTitle: 'Да',
                            cancelTitle: 'Отмена',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true
                        })
                        .then(value => {
                            if (value === true) {
                                // console.log('DeleteObject: ' + JSON.stringify(obj));

                                fetch('/api/forecast?id=' + obj.id, {
                                    method: 'DELETE',
                                })
                                    .then(res => res.text()) // or res.json()
                                    .then(res => {
                                        if (this.headParams !== null) {
                                            this.getFieldsOfTable(this.headParams);
                                        }
                                        console.log(res)
                                    })
                            }
                        })
                        .catch(error => {
                            this.makeToast('danger', 'Ошибка удаления записи', error.toString());
                        });
                }
                // this.edit = true;
            }
        },
        extractObjectById(id, item) {
            let itemObject = {};
            if (id) {
                if (this.storeForEditAndDelete.length > 0) {
                    for (const arr of this.storeForEditAndDelete) {
                        if (arr.id) {
                            if (arr.id === id) {
                                // console.log('id: ' + arr.id + ' indicator: ' + arr.indicatorCustomName);
                                if (item === 'edit') {
                                    itemObject = arr;
                                } else if (item === 'delete') {
                                    itemObject = arr;
                                }
                            }
                        }
                    }
                }
            }
            return itemObject;
        },
        saved(savedObj) {
            // console.log('Сохранённый объект', savedObj);
            if (savedObj && this.headParams !== null) {
                this.getFieldsOfTable(this.headParams);
            }
        },
        //----Извлекается справочник программ----//
        async loadProgram() {   // ---Извлекается справочник Программ---//
            let response = [];
            const result = [];
            this.programArr = [];

            try {
                response = await fetch('/api/dict/program/');
                response = await response.json();
            } catch (error) {
                response = [];
                this.makeToast('danger', 'Ошибка загрузки Программ', error.toString());
            }
            if (response.length > 0) {
                result.push(this.setNameLangs(this.all, 'program'));
                for (const el of response) {
                    // console.log('el: ' + el.id);
                    result.push(this.setNameLangs(el, 'program'))
                }
                // console.log('response: ' + JSON.stringify(result));
            }
            if (this.dictProgramVal !== null) {
                this.dictProgramVal = this.setNameLangs(this.all, 'program');
                this.dictProgramVal = this.setNameLangs(this.dictProgramVal, 'program');
                // console.log('dictProgramVal: ' + JSON.stringify(this.dictProgramVal))
            }
            // console.log('response: ' + JSON.stringify(response));
            this.programArr = result;
            // await this.loadProgramGoals();
        },
        //----Отображение справочника "Целей" в зависимости от выбора программ----//
        async loadGoals() {
            let response = [];
            const result = [];
            this.programGoalArr = [];   //---массив для справочника Целей
            // console.log('this.dictProgramVal.id: ' + this.dictProgramVal.id);
            if (this.dictProgramVal.id) {
                try {
                    // console.log('this.dictProgramVal.id: ' + this.dictProgramVal.id);
                    response = await fetch('/api/program-passport?programId=' + this.dictProgramVal.id);
                    response = await response.json();
                } catch (error) {
                    this.makeToast('Information', 'справочник фильтра "Цели" - пуст', error.toString());
                }
                if (Object.keys(response).length !== 0) {
                    const programPassportGoalsArr = response.programPassportGoals;  //---Извлекается весь подфильтр справочника "Цели" в массив
                    // console.log('Object.keys(response).length: ' + Object.keys(response).length + ' response: ' + JSON.stringify(response) + 'programPassportGoalsArr: ' + JSON.stringify(programPassportGoalsArr));
                    // console.log('response.length: ' + programPassportGoalsArr.length);
                    if (programPassportGoalsArr.length > 0) {
                        // result.push(this.setNameLangs(this.all, 'goal'));
                        for (const goals of programPassportGoalsArr) {
                            // console.log('goals: ' + JSON.stringify(goals.dictProgramGoal));
                            result.push(this.setNameLangs(goals.dictProgramGoal, 'goal'));   //---Создается массив с элементом 'goal'
                        }
                        if (this.dictProgramGoalVal !== null && this.dictProgramGoalVal !== '') {    //---должен отображаться выбранный элемент из спрвочника
                            // this.dictProgramGoalVal = this.setNameLangs(this.all, 'goal');
                            this.dictProgramGoalVal = this.setNameLangs(this.dictProgramGoalVal, 'goal');
                        }
                        this.programGoalArr = result;   //--Присвоение глобальному массиву весь список после фильтра справочника "Программа"
                    } else { //---Если запрос пуст в базе, то происходит очистка параметров
                        this.dictProgramGoalVal = null;
                    }
                }
            } else {    //--Если Id не передался то очищается глобальный массив и происходит возврат в исходное состояние
                this.programGoalArr = [];
                await this.loadProgramGoals();
            }
            this.chgParams(); //---метод для передачи параметров в базу для извлечения данных в грид-таблицу
        },
        //----Извлекается справочник целей----//
        async loadProgramGoals() {
            // console.log('Checking');
            let response = [];
            const result = [];
            this.programGoalArr = [];
            try {
                response = await fetch('/api/dict/program_goals');
                response = await response.json();
            } catch (error) {
                response = [];
                this.makeToast('danger', 'Ошибка загрузки Целей', error.toString());
            }
            if (response.length > 0) {
                result.push(this.setNameLangs(this.all, 'goal'));
                for (const el of response) {
                    result.push(this.setNameLangs(el, 'goal'))
                }
                // console.log('response: ' + JSON.stringify(result));
            }
            if (this.dictProgramGoalVal !== null) {
                this.dictProgramGoalVal = this.setNameLangs(this.all, 'goal');
                this.dictProgramGoalVal = this.setNameLangs(this.dictProgramGoalVal, 'goal');
            }
            this.programGoalArr = result;
        },
        //----Извлекается справочник АБП------//
        async loadAbp() {
            let response = [];
            const result = [];
            this.abpArr = [];
            this.storeAbpArr = [];
            try {
                // response = await fetch('/api-py/get-dict-ved-abp');
                response = await fetch('/api/forecast/dict-ebk-func-abp');
                response = await response.json();
            } catch (error) {
                response = [];
                this.makeToast('danger', 'Ошибка загрузки АБП', error.toString());
            }
            if (response.length > 0) {
                result.push(this.setNameLangs(this.all, 'abp'));
                for (const el of response) {
                    this.storeAbpArr.push(el);
                    result.push(this.setNameLangs(el, 'abp'))
                }
                // console.log('response: ' + JSON.stringify(result));
            }
            if (this.dictAbpVal !== null) {
                this.dictAbpVal = this.setNameLangs(this.all, 'abp');
                this.dictAbpVal = this.setNameLangs(this.dictAbpVal, 'abp');
            }
            this.abpArr = result;
        },
        //----Создание объектов на основе переданных данных из справочников----//
        setNameLangs(obj, codeName) {
            // console.log('i18nString: ' + this.i18nString);
            // console.log('obj: ' + JSON.stringify(obj) +' codeName: ' + codeName)
            // let txt = obj['name_' + this.$i18n.locale];
            let txt = '';
            if (codeName === 'abp') {
                txt = obj['nameRu'];
            } else {
                txt = obj['name_ru'];
            }
            // console.log('txt: ' + txt)
            if (txt === undefined) {
                txt = obj.name_ru;
            }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                txt = obj[codeName] + ' - ' + txt;
                // console.log('txt: ' + txt);
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            // console.log('el: ' + JSON.stringify(el));
            return el;
        },
        //----Передача параметров из шапки с фильтрами----//
        chgParams() {   // передаются параметры фильтра на гриду
            this.headParams = {
                year: this.currentYear,
                program: this.dictProgramVal,
                goal: this.dictProgramGoalVal,
                abp: this.dictAbpVal
            };
            const data = this.headParams;
            this.bar = 5;
            // console.log('data: ' + JSON.stringify(data));
            this.getFieldsOfTable(data);
        },
        //-----Извлекается выбранный год из фильтра----//
        sendCurrentYear() {
            const curYear = this.currentYear;
            // console.log('curYear: ' + curYear);
            return curYear;
        },

        openAll() {
            this.open = !this.open;
            // console.log('this.open: ' + this.open);

            for (const row of this.globalArr) {
                // console.log('row.visible: ' + row.visible + ' this.open: ' + this.open);
                // console.log('rowTotal: ' + JSON.stringify(row));
                row.visible = this.open;
                if ([1, 2].includes(row.levels)) {
                    row.open = this.open;
                }
                if ([1].includes(row.levels)) {
                    row.visible = true;
                }
            }
            // console.log('this.globalArr: ' + JSON.stringify(this.globalArr));
        }, // открывает.закрывает все ветки

        openChilds(parent, bool) {
            // console.log('parent: ' + JSON.stringify(parent));
            parent.open = (bool == undefined ? !parent.open : bool);
            for (const row of this.globalArr) {
                // if ((goal.id === details.goalsId) && (goal.programId === details.programId)) {
                if (parent.ids === row.parent_id) {
                    if ([1, 2].includes(row.levels)) {
                        // console.log('parent.id: ' + parent.id + ' row.parent_id: ' + row.parent_id + ' row: ' + JSON.stringify(row));
                        // console.log('[1, 2].includes(row.levels): '+ [1, 2].includes(row.levels));
                        this.openChilds(row, parent.open);
                    }
                    row.visible = parent.open;
                    // console.log('row.visible: ' + row.visible + ' row: ' + JSON.stringify(row));
                }
            }
        }, // открывает/закрывает ветку до конечного элемента

        rowClass(item, type) {
            // console.log('item: ' + JSON.stringify(item) + ' levels: ' + type + ' item.visible: ' + item.visible);
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке

        //------------Извлекается первичный массив-----//
        async getInitialArray(year, program, goal, abp) {
            this.storeForEditAndDelete = [];
            let response = [];
            if (year || program || goal || abp) {
                this.storeForEditAndDelete = [];
                //--------Извлекаются все данные из json согласно параметрам-----//
                try {
                    // response = await fetch('/api/forecast/sgp?year=' + year + '&program=' + program + '&goal=' + goal + '&abp=' + abp);
                    response = await fetch('/api/stat-dicts/sgp_ti?year=' + year + '&program=' + program + '&goal=' + goal + '&abp=' + abp);
                    response = await response.json();
                    if (response.length > 0) {
                        for (const el of response) {
                            this.storeForEditAndDelete.push(el); // ---Массив для хранения первичных данных
                        }
                    }
                } catch (error) {
                    response = [];
                    this.makeToast('danger', 'Ошибка загрузки первичного массива', error.toString());
                }
                // console.log('storeForEditAndDelete: ' + JSON.stringify(this.storeForEditAndDelete));
            }
        },

        //-----Извлекается массив со всеми данными для грид таблицы----//
        async getFieldsOfTable(header) {
            let response = [];
            if (header !== '') {
                this.forecastFormArr = [];
                this.mainGridObjectArr = [];
                //----Извлекаются параметры фильтра для построения гриды----//
                const years = header.year !== null ? parseInt(header.year) : '';
                const programs = header.program !== null ? header.program.id : '';
                const targets = header.goal !== null ? header.goal.id : '';
                const abps = header.abp !== null ? header.abp.id : '';
                // console.log('year: ' + years + ' program: ' + programs + ' goal: ' + targets + ' abp: ' + abps)
                await this.getInitialArray(years, programs, targets, abps); // ---- Метод для извлечения первичного массива
                this.bar = 95;
                //--------Извлекаются все данные из json согласно параметрам-----//
                try {
                    // response = await fetch('/api/forecast/sgp?year='+years+'&program='+programs+'&goal='+targets+'&abp='+abps);
                    response = await fetch('/api/stat-dicts/sgp_ti?year=' + years + '&program=' + programs + '&goal=' + targets + '&abp=' + abps);
                    response = await response.json();
                    if (response.length > 0) {
                        for (const el of response) {
                            const elem = this.getForecastElementsByParams(el); //----Извлекаются объекты строк массива для гриды
                            this.forecastFormArr.push(elem); // Временно закомментил основной массив со всеми объектами для гриды
                            //----
                            this.mainGridObjectArr.push(el);//Массив с объектами для извлечения массивов с объектами из "Program" и "Goals"
                        }
                        // console.log('Checking_details: ' + JSON.stringify(this.forecastFormArr));
                    }
                } catch (error) {
                    response = [];
                    this.makeToast('danger', 'Ошибка загрузки таблицы ЦИ', error.toString());
                }
                this.preparationForGrid();//---Создаются массивы с объектами без дубликатов из "Program" и "Goals"

                try {
                    // response = await fetch('/api/forecast/sgp?year='+years+'&program='+programs+'&goal='+targets+'&abp='+abps);
                    response = await fetch('/api/stat-dicts/sgp_ti?year=' + years + '&program=' + programs + '&goal=' + targets + '&abp=' + abps);
                    response = await response.json();
                    if (response.length > 0) {
                        for (const el of response) {
                            const elem = this.getForecastElementsByParams(el); //----Извлекаются объекты строк массива для гриды
                            this.forecastFormArr.push(elem); // Временно закомментил основной массив со всеми объектами для гриды
                            //----
                            this.mainGridObjectArr.push(el);//Массив с объектами для извлечения массивов с объектами из "Program" и "Goals"
                        }
                        // console.log('Checking_details: ' + JSON.stringify(this.forecastFormArr));
                    }
                } catch (error) {
                    response = [];
                    this.makeToast('danger', 'Ошибка загрузки таблицы ЦИ', error.toString());
                }
            }
            this.preparationForGrid();//---Создаются массивы с объектами без дубликатов из "Program" и "Goals"
        },

        //------Построение объектов строк гриды----//
        getForecastElementsByParams(elements) {
            let el = {}
            let yearFactOne = '';
            let yearFactTwo = '';
            let yearPlanOne = '';
            let yearPlanTwo = '';
            let yearPlanThree = '';
            let yearPlanFour = '';
            let yearPlanFive = '';

            const chosenYear = this.sendCurrentYear();  //----Год выбранный из фильтра---//
            if (elements !== null && elements !== '') {
                if (elements.years.length > 0) {  //----Нужно понять почему при изменении года в фильтре не меняется размер второго массива
                    if (chosenYear !== null) {
                        // console.log('chosenYear: ' + chosenYear + ' year: '+JSON.stringify(elements.years))
                        // console.log('years: '+years.year+ ' year: '+JSON.stringify(years))
                        //---------Условия фильтра по годам-------//
                        for (const year of elements.years) {
                            if ((chosenYear - 1) == year.year) {
                                yearFactTwo = year.fact;
                            }
                            if ((chosenYear - 2) == year.year) {
                                yearFactOne = year.fact;
                            }
                            if (chosenYear == year.year) {
                                yearPlanOne = year.plan;
                            }
                            if ((chosenYear + 1) == year.year) {
                                yearPlanTwo = year.plan;
                            }
                            if ((chosenYear + 2) == year.year) {
                                yearPlanThree = year.plan;
                            }
                            if ((chosenYear + 3) == year.year) {
                                yearPlanFour = year.plan;
                            }
                            if ((chosenYear + 4) == year.year) {
                                yearPlanFive = year.plan;
                            }
                        }
                        let abp = '';
                        let bp = '';
                        if (elements.abps && elements.abps.length > 0) {
                            const ulArr = [];
                            const bpUlArr = [];
                            let noDuplicates = [];
                            let noBpDuplicates = [];
                            let listOfABP = '<ul class="listABP">';
                            let listOfBP = '<ul class="listBP">';
                            // console.log('elements.abps: ' + JSON.stringify(elements.abps));
                            for (const ab of elements.abps) {
                                const tempArr = {};
                                const bpArr = {};
                                bpArr['id'] = ab.dictEbkFuncDto.id;
                                bpArr['item'] = ab.dictEbkFuncDto.prg + ' - ' + ab.dictEbkFuncDto.nameRu;
                                // console.log('ab.dictEbkFuncDto.id: ' + ab.dictEbkFuncDto.id + ' ab.dictEbkFuncDto.abp: ' + ab.dictEbkFuncDto.abp + ' ab.dictEbkFuncDto.nameRu: ' + ab.dictEbkFuncDto.nameRu);
                                bpUlArr.push(bpArr);
                                for (const elAbp of this.storeAbpArr) {
                                    if (ab.abp === elAbp.abp) {
                                        // console.log('ABP: ' + ab.abp);
                                        tempArr['id'] = elAbp.abp;
                                        tempArr['item'] = elAbp.abp + ' - ' + elAbp.nameRu;
                                        ulArr.push(tempArr);
                                    }
                                }
                            }
                            // console.log('ulArr: ' + JSON.stringify(ulArr));
                            noDuplicates = this.getOnlyObjects(ulArr);
                            for (const abpEl of noDuplicates) {
                                listOfABP += '<li>' + abpEl.item + '</li>';
                            }
                            noBpDuplicates = this.getOnlyObjects(bpUlArr);
                            for (const bpEl of noBpDuplicates) {
                                listOfBP += '<li>' + bpEl.item + '</li>'
                            }

                            listOfABP += '</ul>';
                            listOfBP += '</ul>';
                            abp = listOfABP;
                            bp = listOfBP;
                            // console.log('abp: ' + JSON.stringify(abp));
                        }
                        const idObject = ((elements.id !== '' && elements.id !== null) ? elements.id : '');
                        const programId = ((elements.program !== '' && elements.program !== null) ? elements.program.id : '');
                        const prg = ((elements.program !== '' && elements.program !== null) ? elements.program.name_ru : '');
                        const indicators = ((elements.indicator !== '' && elements.indicator !== null) ? elements.indicator.name_ru : '');
                        const target = ((elements.goals !== '' && elements.goals !== null) ? elements.goals.name_ru : '');
                        const unit = ((elements.statUnit != null && elements.statUnit != '') ? elements.statUnit.nationalSymbol : ''); // elements.statUnit.name_ru
                        const goalsId = (elements.goals != null) ? elements.goals.id : '';

                        el = {
                            id: idObject,
                            programId: programId,
                            sgp: prg,
                            goals: target,
                            targetIndicators: indicators,
                            abp: abp,
                            bp: bp,
                            unitFromStat: unit,
                            yearOne: yearFactOne,
                            yearTwo: yearFactTwo,
                            yearThree: yearPlanOne,
                            yearFour: yearPlanTwo,
                            yearFive: yearPlanThree,
                            yearSix: yearPlanFour,
                            yearSeven: yearPlanFive,
                            goalsId: goalsId,
                            levels: 3
                        }
                    }
                }
            }
            return el;
        },
        //-----Дробление массива по объектам для извлечения группированных объектов из справочника "Program" и "Goals"
        preparationForGrid() {
            const programListArr = [];
            const goalListArr = [];
            //----Извлекаются объекты без дубликатов по Программам
            for (const mainEl of this.mainGridObjectArr) {
                if (mainEl.goals !== null) {
                    mainEl.program['isGoal'] = true;
                } else {
                    mainEl.program['isGoal'] = false;
                }
                if (mainEl.indicator !== null) {
                    mainEl.program['isIndicator'] = true;
                } else {
                    mainEl.program['isIndicator'] = false;
                }
                programListArr.push(mainEl.program);
            }
            this.programObjArr = this.getOnlyObjects(programListArr); //----Извлечение массива с объектами "Программ" без дубликатов
            // console.log('ProgramIsFirstLevel: ' + JSON.stringify(this.programObjArr));
            //----Извлекаются объекты по Целям без дубликатов
            for (const goalEl of this.mainGridObjectArr) {
                if (goalEl.goals !== null) {
                    goalEl.goals['programId'] = goalEl.program.id;
                    goalListArr.push(goalEl.goals);
                }
            }
            this.goalObjArr = this.getOnlyGoalObjects(goalListArr); //----Извлечение массива с объектами "Целей" без дубликатов
            // console.log('GoalIsSecondLevel: ' + JSON.stringify(this.goalObjArr));
            this.createGlobalTreeTableObject(); // ----Построение древовидного массива с объектами
            // this.methodForChecking(this.programObjArr);
        },
        // -----Функция для очистки массива Цели от дубликатов
        getOnlyGoalObjects(arr) {
            const filteredObjectArr = arr.reduce((enteredArr, current) => {
                const x = enteredArr.find(item => item.id === current.id && item.programId === current.programId);
                if (!x) {
                    return enteredArr.concat([current]);
                } else {
                    return enteredArr;
                }
            }, []);
            return filteredObjectArr;
        },
        // -----Функция для очистки массива от дубликатов
        getOnlyObjects(arr) {
            const filteredObjectArr = arr.reduce((enteredArr, current) => {
                const x = enteredArr.find(item => item.id === current.id);
                if (!x) {
                    return enteredArr.concat([current]);
                } else {
                    return enteredArr;
                }
            }, []);
            return filteredObjectArr;
        },
        // -----Функция для построение древовидного массива с объектами----- //
        async createGlobalTreeTableObject() {
            this.mainArr = [];
            this.globalArr = [];
            //-----Самый верхних объект массива----//
            for (const arr of this.programObjArr) {
                const mainObj = {
                    code: arr.code,
                    value: arr
                }
                const obj = Object.create(mainObj);
                this.mainArr.push(obj); //-----------В самый верхний объект массива был вложен массив со списком программ-----//
            }
            // console.log('FirstLayer: ' + JSON.stringify(this.mainArr));
            let count = 0;
            //----Первый уровень дерева (извлекается объект с название программы)
            for (const firstLevel of this.mainArr) {
                if (firstLevel.value.isGoal === true && firstLevel.value.isIndicator === true) {
                    // console.log('firstLevel.isGoal: ' + firstLevel.value.isGoal);
                    const goalArr = [];
                    firstLevel.value['levels'] = 1;
                    firstLevel.value['sgp'] = firstLevel.value.name_ru;
                    firstLevel.value['programId'] = firstLevel.value.id;
                    firstLevel.value['ids'] = firstLevel.value.id;
                    // console.log('firstLevel.value: ' + JSON.stringify(firstLevel.value));
                    //----Второй уровень дерева (извлекается объект с названием цели)
                    for (const goal of this.goalObjArr) {
                        if (firstLevel.value.programId === goal.programId) {
                            const detailedList = [];
                            goal['levels'] = 2;
                            goal['goals'] = goal.name_ru;
                            count += 1;
                            goal['ids'] = count;
                            // console.log('SecondLevelGoal: ' + JSON.stringify(goal));
                            //----Третий уровень дерева (извлекается массив с объектами соответствующие целям программ)
                            for (const details of this.forecastFormArr) {
                                if ((goal.id === details.goalsId) && (goal.programId === details.programId)) {
                                    details['ids'] = details.id;
                                    // console.log('ThirdLevelIndicator: ' + JSON.stringify(details));
                                    const innerSecObj = {
                                        value: details
                                    }
                                    const innerSec = Object.create(innerSecObj);
                                    detailedList.push(innerSec);
                                }
                            }
                            goal['child'] = detailedList; // ----Второе вложение массива с объектами

                            const innerObj = {
                                code: firstLevel.value.code,
                                value: goal
                            }
                            const innerFirst = Object.create(innerObj);
                            goalArr.push(innerFirst);
                        }
                    }
                    firstLevel.value['child'] = goalArr; // ---Первое вложение массива с объектами
                }
            }

            // console.log('this.mainArr: ' + JSON.stringify(this.mainArr));
            //----Создается таблица на основе дерева объектов
            for (const item of this.mainArr) {
                if (item.value.isGoal === true && item.value.isIndicator === true) {
                    await this.createTable(item.value, 0);
                }
            }
            // console.log('globalArr: ' + JSON.stringify(this.globalArr));
            this.openAll();
            this.bar = 100;
        },
        //----Создание таблицы на основе дерева
        createTable(el, parent_id) {
            if (el) {
                if (el.id) {
                    const item = Object.assign({}, el);

                    //------Добавляется "hasChild": "true" элемент в объект, только на два первых уровней
                    if (item.levels !== 3) {
                        this.$set(item, 'hasChild', true);
                    }
                    this.$set(item, 'open', false);
                    this.$set(item, 'parent_id', parent_id);
                    this.$set(item, 'visible', true);
                    this.$set(item, 'more', false);

                    //-------------В массив добавляют только объекты в раздробленном виде без элементов child
                    this.$set(item, 'index', this.globalArr.length);
                    this.$set(this.globalArr, this.globalArr.length, item);

                    if (item.hasChild) {
                        // console.log('item: ' + JSON.stringify(item));
                        if (item.child) {
                            for (const ch of item.child) {
                                // console.log('ch.value:' + JSON.stringify(ch.value) + ' item.id: ' + item.id);
                                this.createTable(ch.value, item.ids); // ---Выполняется рекурсия для извлечения всех объектов внутри своего массива
                            }
                            delete item.child; //---Удаются все вложенные массивы с объектами которые начинаются с элемента "child"
                        }
                    }
                }
            }
        },
        //------Метод для отображения сообщения с ошибками----//
        makeToast(title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                autoHideDelay: 5000,
                appendToast: true
            });
        } // сообщение с ошибкой
    }
}
</script>

<style>
.is-hidden {
    display: none !important;
}
.listABP {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}
.listABP li{
    margin-bottom: 15px;
}
.listBP {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}
.listBP li{
    margin-bottom: 15px;
}
</style>